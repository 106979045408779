import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Slider from './Slider';
import ContactForm from './Contact';
import Hero from './Hero';
import Achievements from './Achivements'; // Corrected spelling
import Clients from './Client';
import Footer from './Footer';
import About from './About';
import AEGLogo from './image/Logo/AEG logo.jpg';

const Home = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu
  const prevScrollY = useRef(0);
  const HomeRef = useRef(null);
  const aboutRef = useRef(null);
  const achievementsRef = useRef(null); // Corrected spelling
  const contactRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      setIsSticky(currentScrollY > 40);
      setShowNav(currentScrollY <= 100 || currentScrollY <= prevScrollY.current);

      // Close the mobile menu if the user scrolls
      if (isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }

      prevScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobileMenuOpen]);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle mobile menu state
  };

  const selectItem = (element) => {
    document.querySelectorAll('.selected').forEach(item => {
      item.classList.remove('selected');
    });
    element.classList.add('selected');
  };

  const selectBox = (element) => {
    document.querySelectorAll('.selected-box').forEach(item => {
      item.classList.remove('selected-box');
    });
    element.classList.add('selected-box');
  };

  return (
    <div className='hello'>
      {/* 

// Update the header section in your Home.js */}

      <header className={`header ${isSticky ? 'sticky-nav' : ''} ${showNav ? 'visible-nav' : 'hidden-nav'}`}>
        <nav className="w-full">
          <div className="container1 mx-auto flex justify-between items-center px-50 py-10">
            <Link to='' className="brand-link">
              <img src={AEGLogo} alt="AEG Logo" className="h-14" />
            </Link>

            <button className="hamburger lg:hidden" onClick={toggleMobileMenu}>
              &#9776;
            </button>

            <ul className={`nav-links ${isMobileMenuOpen ? 'show' : ''}`}>
              <li><Link to="/"><button onClick={() => scrollToSection(HomeRef)}>Home</button></Link></li>
              {/* <li><button onClick={() => scrollToSection(aboutRef)}>About Us</button></li>
        <li><button onClick={() => scrollToSection(achievementsRef)}>Achievements</button></li>
        <li><button onClick={() => scrollToSection(contactRef)}>Contact Us</button></li> */}

              <li className="relative group">
                <button className="flex items-center gap-1">
                  Conference
                  <sup className="bg-red-500 text-white text-xs px-1 rounded">New</sup>
                </button>

                <div className="absolute hidden group-hover:block">
                  <div className="dropdown-container">
                    {/* Left Menu Section */}
                    <div className="menu-section">
                      <ul>
                        <li><Link to="/Conference" className="text-gray-800 hover:text-blue-600">About Conferences</Link></li>
                        <li><Link to="/PlanScientificPage" className="text-gray-800 hover:text-blue-600">Plan a Scientific Conference</Link></li>
                        <li><Link to="/Sponsor" className="text-gray-800 hover:text-blue-600">Sponsors & Exhibitors</Link></li>
                        <li><Link to="/ConferenceAward" className="text-gray-800 hover:text-blue-600">Awards & Recognition</Link></li>
                        <li><Link to="/NewWorkshop" className="text-gray-800 hover:text-blue-600">Workshops & Courses</Link></li>
                        <li><Link to="/faq" className="text-gray-800 hover:text-blue-600">Conference FAQ</Link></li>
                      </ul>
                    </div>

                    {/* Right Cards Section */}
                    <div className="cards-section">
                      <Link to="/UpcomingConference" className="menu-card bg-slate-200 text-black">
                        <i className="fas fa-calendar-alt text-3xl mb-2"></i>
                        <span className="text-center">Upcoming Conferences</span>
                      </Link>

                      <Link to="/Webinar" className="menu-card bg-slate-300 text-black">
                        <i className="fas fa-video text-3xl mb-2"></i>
                        <span className="text-center">Webinars</span>
                      </Link>

                      <Link to="/register" className="menu-card bg-slate-200 text-black">
                        <i className="fas fa-calendar-check text-3xl mb-2"></i>
                        <span className="text-center">Past Conferences</span>
                      </Link>

                      <Link
                        to="/ConferenceGallery"
                        className="menu-card bg-slate-300 text-black hover:bg-blue-800 transition-colors"
                      >
                        <i className="fas fa-photo-video text-3xl mb-2"></i>
                        <span className="text-center">Conference Videos & Galleries</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li><button onClick={() => scrollToSection(aboutRef)}>About Us</button></li> */}
              <li><button onClick={() => scrollToSection(achievementsRef)}>Achievements</button></li>
              <li><button onClick={() => scrollToSection(contactRef)}>Contact Us</button></li>
              <li><Link to="/products"><button>Products</button></Link></li>
              <li><Link to="/services"><button>Services</button></Link></li>
            </ul>
          </div>
        </nav>
      </header>


      <div ref={HomeRef} id="home">
        <Slider />
      </div>

      <div ref={aboutRef} id="about">
        <About />
      </div>

      <div ref={achievementsRef} id="achievements">
        <Achievements />
      </div>
      <Clients />
      <div ref={contactRef} id="contact">
        <ContactForm />
      </div>

      <Hero />
      <Footer />
    </div>
  );
};

export default Home;