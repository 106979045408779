import React, { useState } from 'react';
import './ConferenceList.css'; // Create this CSS file for styling
import { Link } from 'react-router-dom';

const ConferenceList = () => {
  const [activeTab, setActiveTab] = useState('attend');

  return (
    <div className="conference-section">
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === 'attend' ? 'active' : ''}`}
          onClick={() => setActiveTab('attend')}
        >
          Attend a conference
        </button>
        <button
          className={`tab-button ${activeTab === 'organize' ? 'active' : ''}`}
          onClick={() => setActiveTab('organize')}
        >
          Organize a conference
        </button>
        <button
          className={`tab-button ${activeTab === 'join' ? 'active' : ''}`}
          onClick={() => setActiveTab('join')}
        >
          Why join our conferences?
        </button>
        {/* Uncomment if needed
        <button
          className={`tab-button ${activeTab === 'contact' ? 'active' : ''}`}
          onClick={() => setActiveTab('contact')}
        >
          Contact us
        </button>
        */}
      </div>

      <div className="content">
        {activeTab === 'attend' && (
          <div className="attend-content">
            <h2>Attend a conference</h2>
            <p>AEG Consultancy Services Pvt. Ltd. offers opportunities to engage with cutting-edge research, network with experts, and stay updated on the latest industry trends by attending our conferences.</p>
          </div>
        )}
        {activeTab === 'organize' && (
          <div className="organize-content">
            <h2>Organize a conference</h2>
            <p>Collaborate with AEG to organize professional conferences tailored to your field, providing a platform for knowledge exchange, research presentations, and fostering academic-industry partnerships.</p>
          </div>
        )}
        {activeTab === 'join' && (
          <div className="join-content">
            <h2>Why join our conferences?</h2>
            <p>Joining our conferences connects you with a diverse research community, facilitates access to high-quality discussions, and opens up avenues for collaboration in both academic and industrial sectors.</p>
          </div>
        )}
        {/* Uncomment if needed
        {activeTab === 'contact' && (
          <div className="contact-content">
            <Link to="/ContactForm">
              <h2>Contact us</h2>
            </Link>
          </div>
        )}
        */}
      </div>
    </div>
  );
};

export default ConferenceList;